import Resource from "./utils/resource"
import { serializeToQueryString } from "helpers/misc";
import { observable, action, flow } from "mobx";
import AsyncStore from "./utils/AsyncStore";
import CONSTANTS from "../utils/constants";

export default class Audit extends Resource {
  @observable listAuditStore = new AsyncStore();

  @action.bound
  listAudit = flow(function* (params) {
    this.listAuditStore.loading = true;
    try {
      var queryString = serializeToQueryString(params);
      const response = yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/audit${queryString}`
      ).then((r) => r.json());

      if (response.success === false) throw Error(response.error.Message);

      this.listAuditStore.data = response;
      this.listAuditStore.loading = false;
      this.listAuditStore.loaded = true;
    } catch (e) {
      this.listAuditStore.error = e;
      this.listAuditStore.loading = false;
      this.notification.showError(e.message);
    }
  })

  async listApiHandler(params) {
    console.log("listApiHandler", params);
    var queryString = serializeToQueryString(params);
    const response = await this.fetch(
      `${CONSTANTS.ADMIN_API_URL}/audit${queryString}`
    ).then((r) => r.json());
    return response.data;
  }
}