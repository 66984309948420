import React from "react";
import {
    Paper,
    Grid,
    Button,
    LinearProgress,
} from "@material-ui/core";
import UniversalFormList from "app/components/UniversalForm/UniversalFormList";
import UniversalFormBuilder from "app/components/UniversalForm/UniversalFormBuilder";

const UniversalForm = (props) => {
    return (
        <UniversalFormList />
    )
};

export default UniversalForm;
