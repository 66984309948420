import { observable, flow, action } from "mobx";
import { capitalizeFirstLetter } from "../../../helpers/misc";
import CONSTANTS, { AUTHORIZATION_TYPE } from "../../../app/utils/constants";
import { startLoading, endLoading, NOT_LOADED } from "../../../helpers/loadstates";
import { get as _get } from "lodash";
import { createTransformer } from "mobx-utils";
import AsyncStore from "./AsyncStore";

export class Resource {
  auth = {};
  notification = {};
  @observable loading = false;
  @observable loaded = false;
  @observable total = 0;
  @observable error = {};
  @observable data = [];
  @observable datum = {};

  constructor({ notification, authentication, ...store }) {
    console.log('resource controller', store);
    this.authentication = authentication;
    this.notification = notification;
    this.store = store;
  }

  async fetch(url = '', data = {}, tenantId = '', authType = AUTHORIZATION_TYPE.ADMIN,) {
    try {
      let tokenData = localStorage.getItem("token");
      
      if (tokenData === null) {
        throw Error("Invalid/Missing Session.");
      } else {
        let jwtToken;

        if (authType === AUTHORIZATION_TYPE.TENANT) {
          jwtToken = `${CONSTANTS.ENV}-${tenantId}-tenant`;
        } else {
          const parsedTokenData = JSON.parse(tokenData);
          jwtToken = parsedTokenData.accessToken.jwtToken;
        }

        console.log(url, data);

        let result = await fetch(url, {
          ...data,
          headers: {
            ...data.headers,
            Authorization: `Bearer ${jwtToken}`,
            AuthorizationType: authType
          }
        });

        if (result.status === 403) {
          this.authentication.logout();
        }

        return result;
      }
    } catch (e) {
      this.authentication.logout();
      throw e;
    }
  }

  set = flow(function* (id, path, val) {
    // let obj = this.datum[id]
    // dotProp.set(obj, path, val);
    this.datum[id] = {};
  });

  @observable fetchListStore = new AsyncStore();

  @action.bound
  fetchList = flow(function* (params) {
    console.log(params);
    this.fetchListStore.loading = true;
    this.fetchListStore.error = null;
    try {
      let results = yield this.listApiHandler(params);
      results = results.map((result, index) => {
        this.datum[result.id] = this.listMapper(
          result,
          this.datum[result.id],
          index,
          results
        );
        return result.id;
      });
      this.fetchListStore.data = results;
      this.fetchListStore.loading = false;
      this.fetchListStore.loaded = true;
    } catch (e) {
      console.error(e);
      this.fetchListStore.error = e;
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  /**
   *
   * @param {*} newData
   * @param {*} oldData
   * @param {*} _index
   * @param {*} _results
   */
  listMapper(newData, oldData, _index, _results) {
    return {
      ...(oldData ? oldData : {}),
      ...(newData ? newData : {}),
    };
  }

  @observable fetchOneStore = new AsyncStore();

  fetchOne = flow(function* (args) {
    this.fetchOneStore.loading = true;
    this.fetchOneStore.error = null;
    try {
      const result = yield this.getApiHandler(args);
      // this._data.set(args, { ...this._data.get(args), ...results });
      this.datum[result.id] = result;
      this.fetchOneStore.data = result;
      this.fetchOneStore.loading = false;
      // this.loaded = true;
    } catch (e) {
      this.notification.showError(this.errorFormatter("get", e));
      this.fetchOneStore.error = e;
    }
  });

  get = createTransformer(([defaultVal, ...path]) => {
    const val = _get(this.datum, path, defaultVal);
    if (val) return val;
    return defaultVal;
  });

  delete = flow(function* (args) {
    console.log("args", args);
    this.loading = true;
    this.error = {};
    try {
      const results = yield this.deleteApiHandler(args);
      this.loading = false;
      this.loaded = true;
      yield this.list();
    } catch (e) {
      console.error(e);
      this.error = e;
      this.notification.showError(this.errorFormatter("delete", e));
    }
  });

  @observable createStore = new AsyncStore();

  create = flow(function* (args) {
    console.log("create", args);
    this.createStore.loading = true;
    this.createStore.error = null;
    try {
      yield this.createApiHandler(args);
      this.createStore.loading = false;
      this.createStore.loaded = true;
    } catch (e) {
      console.error(e);
      this.createStore.error = e;
      this.notification.showError(this.errorFormatter("create", e));
    }
  });

  @observable updateStore = new AsyncStore();

  update = flow(function* (args) {
    this.updateStore.loading = true;
    this.updateStore.error = null;
    try {
      yield this.updateApiHandler(args);
      this.updateStore.loading = false;
      this.updateStore.loaded = true;
    } catch (e) {
      console.error(e);
      this.updateStore.error = e;
      this.notification.showError(this.errorFormatter("create", e));
    }
  });

  registerSubResource = ({ resourceNamePlural }, { fetchList }) => {
    const syncState = `__${resourceNamePlural}_sync`;
    const fetchAllName = `fetch${capitalizeFirstLetter(resourceNamePlural)}`;
    this[fetchAllName] = flow(function* ({ id, ...params }) {
      try {
        this.datum[id][syncState] = startLoading(this.datum[id][syncState]);
        let resourceData = yield fetchList({ id, ...params });
        this.datum[id][syncState] = endLoading(this.datum[id][[syncState]]);
        resourceData = resourceData.map((resource) => {
          this.store[resourceNamePlural].inject(resource);
          return resource.id;
        });
        this.datum[id][resourceNamePlural] = resourceData;
      } catch (e) {
        console.error(
          `****** \n Error for resource ${resourceNamePlural} while ${fetchAllName}:  ${e.message} \n*******`
        );
      }
    });
  };

  inject = flow(function* (args) {
    this.datum[args.id] = args;
  });

  errorFormatter(type, e) {
    return e.message;
  }

  messageFormatter(type, e) {
    return "done!";
  }

  async listApiHandler(args) {
    return [];
  }
  async getApiHandler(args) {
    return {};
  }

  async deleteApiHandler(args) { return {}; }

  async createApiHandler(args) { return {}; }

  async updateApiHandler(args) { return {}; }
}

export default Resource;
