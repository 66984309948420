/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, Router } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

import { observer } from 'mobx-react';
import { useStores } from "./hooks/useStores";

export const Routes = observer(function () {
    const { authentication } = useStores();
    const isAuthorized = authentication.authenticatedData != null;
    console.log("isAuthorized", isAuthorized)
    // return <h1>HELLOOWWWW~!!! isAuthorized</h1>
    return (
        <Switch>
            {/* <Layout>
                <BasePage />
            </Layout> */}
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />


            {!isAuthorized ? (
                <Redirect to="/auth/login" />
            ) : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )}
        </Switch>
    );
})
