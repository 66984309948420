import { Button } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import Modal from "../Core/Modal";
import TextInputField from "../formik/TextInputField";
import * as Yup from "yup";
import { useStores } from "app/hooks/useStores";
import { useMutation, useQueryClient } from "react-query";

const IPModal = ({ onClose, selectedRow, setSelectedRow }) => {
  const { ipManagement, notification } = useStores();
  const { addIpAddress, updateIpAddress } = ipManagement;
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
    setSelectedRow({});
  };

  const isEditing = Object.keys(selectedRow).length > 0;

  const initialValues = useMemo(() => {
    const { name, ip_address } = selectedRow;
    return isEditing ? { name, ip_address } : { name: "", ip_address: "" };
  }, [selectedRow]); // eslint-disable-line react-hooks/exhaustive-deps

  const addIpAddressMutation = useMutation((data) => addIpAddress(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("ipList");
      notification.showSuccess(`Number was added successfully`);
      handleClose();
    },
    onError: (error) => {
      notification.showError(
        "An unexpected error occurred while attempting to add the number"
      );
    },
  });

  const updateIpAddresMutation = useMutation((data) => updateIpAddress(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("ipList");
      notification.showSuccess(`IP Address was updated successfully`);
      handleClose();
    },
    onError: (error) => {
      notification.showError(
        "An unexpected error occurred while attempting to update the IP Address"
      );
    },
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .max(56),
    ip_address: Yup.string()
      .required("IP Address is required")
      .matches(
        /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/,
        "Not a valid IP Address"
      )
      .max(15),
  });

  const handleSubmit = ({ name, ip_address }) => {
    const data = { name, ip_address };
    isEditing
      ? updateIpAddresMutation.mutateAsync({ id: selectedRow.id, ...data })
      : addIpAddressMutation.mutateAsync(data);
  };

  return (
    <Modal
      size="sm"
      header={isEditing ? "Edit IP Address" : "Add IP Address"}
      onClose={handleClose}
      body={
        <div>
          <div className="d-flex flex-column justify-content-center">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({ isSubmitting }) => (
                <Form>
                  <label className="required mt-4 mb-2">Name</label>
                  <TextInputField
                    fieldName="name"
                    disabled={isSubmitting}
                    maxLength={56}
                    placeholder="Enter Name"
                    required
                  />
                  <label className="required mt-4 mb-2">IP Address</label>
                  <TextInputField
                    fieldName="ip_address"
                    disabled={isSubmitting}
                    maxLength={15}
                    placeholder="Enter IP Address"
                    required
                  />
                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      className="primary-btn mr-auto"
                      variant="outlined"
                      disabled={isSubmitting}
                      disableElevation
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="secondary-btn"
                      variant="contained"
                      disabled={isSubmitting}
                      disableElevation
                      color="secondary"
                      style={{ width: "auto" }}
                    >
                      {isEditing ? "Update" : "Add"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
    />
  );
};

export default IPModal;
