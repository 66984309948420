import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Field } from 'formik';
import { TextField } from "@material-ui/core";

const styles = (theme) => ({
    fieldLabel: {
        fontFamily: "Montserrat",
        fontSize: 11,
    },
});

const getInputClasses = (meta, fieldName) => {
    let result = "form-control form-control-solid h-auto form-control-background";
    if (meta.touched && meta.error) {
        result += " is-invalid";
    }

    if (meta.touched && !meta.error) {
        result += " is-valid";
    }

    return result;
};

const TextInputField = withStyles(styles)(({ fieldLabel, fieldName,
    maxLength, rows, classes, placeholder, disabled, mt = 3, required, ...props }) => {

    return (
        <Field name={fieldName}>
            {({ field, form, meta }) => {
                return (
                    <div className='d-flex flex-column w-100'>
                        {fieldLabel && <label className={`${required ? "required" : ""}`}>{fieldLabel}</label>}
                        <TextField
                            {...field}
                            id={fieldName}
                            name={fieldName}
                            placeholder={placeholder}
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled={disabled === true}
                            required={required === true}
                            inputProps={{ maxLength, style: props.style }}
                            error={meta.touched && meta.error}
                            className={`${getInputClasses(meta)}`}
                        />
                        {meta.touched && meta.error && (
                            <div className="error invalid-feedback">
                                {meta.error}
                            </div>
                        )}
                    </div>
                );
            }}
        </Field>
    );
});

export default TextInputField;