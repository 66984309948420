import { observer } from "mobx-react";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useStores } from "app/hooks/useStores";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AlertDialog from "app/components/Core/AlertDialog";
import DomainModal from "app/components/DomainModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}));

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "domain",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "Domain",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "Action",
  },
];

function OriginationDomain() {
  const { notification, originationDomain } = useStores();
  const { getDomainList, deleteDomain } = originationDomain;
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const { data, isFetching } = useQuery(["domainList"], getDomainList);
  const listData = data || [];

  const queryClient = useQueryClient();

  const deleteDomainMutation = useMutation((id) => deleteDomain(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("domainList");
      notification.showSuccess(`Domain was deleted successfully`);
    },
    onError: (error) => {
      notification.showError(
        "An unexpected error occurred while attempting to delete the Domain"
      );
    },
    onSettled: () => {
      setSelectedRow({});
    },
  });

  const handleDelete = () => {
    setShowAlert(false);
    deleteDomainMutation.mutateAsync(selectedRow.id);
  };

  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <EnhancedTableToolbar
          title="Origination Domain"
          numSelected={0}
          loading={isFetching || deleteDomainMutation.isLoading}
        />
        {listData.length < 1 && (
          <div>
            <Button
              disabled={isFetching || deleteDomainMutation.isLoading}
              startIcon={<AddIcon />}
              variant="contained"
              disableElevation
              size="large"
              color="secondary"
              className="mr-3"
              onClick={() => setShowAddModal(true)}
            >
              Add
            </Button>
          </div>
        )}
      </div>

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            headCells={headCells}
            classes={classes}
            numSelected={0}
            rowCount={listData.length}
          />

          <TableBody>
            {listData?.length > 0 &&
              listData?.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.domain}</TableCell>
                    <TableCell align="left" scope="row">
                      <Tooltip title="Edit" aria-label="edit">
                        <IconButton
                          disabled={
                            isFetching || deleteDomainMutation.isLoading
                          }
                          onClick={() => {
                            setSelectedRow(row);
                            setShowAddModal(true);
                          }}
                          color="secondary"
                          aria-label="Edit"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Delete" aria-label="delete">
                        <IconButton
                          disabled={
                            isFetching || deleteDomainMutation.isLoading
                          }
                          onClick={() => {
                            setSelectedRow(row);
                            setShowAlert(true);
                          }}
                          color="secondary"
                          aria-label="Delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {showAddModal && (
        <DomainModal
          onClose={() => setShowAddModal(false)}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}
      {setShowAlert && (
        <AlertDialog
          open={showAlert}
          onConfirm={handleDelete}
          onClose={() => setShowAlert(false)}
          title="Are you sure?"
          message="Are you sure you want to permanently delete this Domain?"
        />
      )}
    </Paper>
  );
}

export default observer(OriginationDomain);
