/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useFlags } from "launchdarkly-react-client-sdk";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const featureFlags = useFlags();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/office")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/office">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Office List</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/auditlog")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/auditlog">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Audit Log</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/ip-management")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ip-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">IP Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/origination-domain")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/origination-domain">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Origination Domain</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive("/office/create")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/office/create">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Create Office</span>
          </NavLink>
        </li> */}
         {featureFlags.extendAdminDashboardUniversalForms && (
          <li
            className={`menu-item ${getMenuItemActive("/universalform")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/universalform">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              </span>
              <span className="menu-text">Universal Forms</span>
            </NavLink>
          </li>
        )}
        <li
          className={`menu-item ${getMenuItemActive("/logout")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logout">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Logout</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/logs")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logs">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Logs</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/* Components */}
        {/* begin::section */}
        {/*end::1 Level*/}
      </ul>


      {/* end::Menu Nav */}
    </>
  );
}
