import { observable, flow } from "mobx";
import { startLoading, endLoading, NOT_LOADED } from "../../../helpers/loadstates";
import { get as _get } from "lodash";
import { createTransformer } from "mobx-utils";
import { Resource } from "./resource";

export class PagingResource extends Resource {
    @observable pagerData = [];
    @observable page = 0;
    @observable pageSize = 20;
    @observable pagerExhausted = false;
    @observable filters = {};

    setFilters = flow(function* (params) {
        this.filters = params;
        yield this.resetPager();
        yield this.fetchMore();
    });

    resetPager = flow(function* (params) {
        this.page = 0;
        this.pagerExhausted = false;
        this.pagerData = [];
    });
    
    fetchMore = flow(function* (params) {
        if (this.pagerExhausted) return;

        this.loading = true;
        var page = this.page;
        try {
            let results = yield this.listApiHandler({
                ...params,
                ...(this.filters),
                rows: this.pageSize,
                offset: this.pageSize * page,
            });
            results = results.map((result, index) => {
                this.datum[result.id] = this.listMapper(
                    result,
                    this.datum[result.id],
                    index,
                    results
                );
                return result.id;
            });
            this.pagerData = this.page > 0 ? [...(this.pagerData), ...results] : results;
            this.loading = false;
            this.loaded = true;
            this.page = page;
            this.pagerExhausted = true; //this.pagerData.length % this.pageSize === 0;
        } catch (e) {
            console.error(e);
            this.error = e;
            this.notification.showError(this.errorFormatter("list", e));
        }
    });

}

export default PagingResource;
