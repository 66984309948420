import { AUTHORIZATION_TYPE } from "app/utils/constants";
import { observable, flow, action } from "mobx";

import AsyncStore from "./utils/AsyncStore";
import Resource from "./utils/resource";

export default class UniversalFormStore extends Resource {
  @observable listFormsStore = new AsyncStore();

  @action.bound
  listOfForms = flow(function*() {
    this.listFormsStore.loading = true;
    try {
      const response = yield this.fetch(
        `${process.env.REACT_APP_BASE_URL}/universal-form`
      ).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        this.listFormsStore.data = response;
        this.listFormsStore.loading = false;
        this.listFormsStore.loaded = true;
        return response.data;
      }
    } catch (e) {
      console.log("e==>", e);
      this.listFormsStore.error = e;
      this.listFormsStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  });

  @observable listFormsStore = new AsyncStore();

  @action.bound
  formByUUID = flow(function*({ uuid }) {
    this.listFormsStore.loading = true;
    try {
      const response = yield this.fetch(
        `${process.env.REACT_APP_BASE_URL}/universal-form/uuid`
      ).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        this.listFormsStore.data = response;
        this.listFormsStore.loading = false;
        this.listFormsStore.loaded = true;
        return response.data;
      }
    } catch (e) {
      console.log("e==>", e);
      this.listFormsStore.error = e;
      this.listFormsStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  });

  @observable addFormsStore = new AsyncStore();

  @action.bound
  addForm = flow(function*(args) {
    this.addFormsStore.loading = true;
    try {
      const response = yield this.fetch(
        `${process.env.REACT_APP_BASE_URL}/universal-form`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(args),
        }
      ).then((res) => res.json());

      if (!response === true) {
        throw Error(response.error.Message);
      } else {
        this.addFormsStore.data = response;
        this.addFormsStore.loading = false;
        this.addFormsStore.loaded = true;
        return response.data;
      }
    } catch (e) {
      console.log("e==>", e);
      this.addFormsStore.error = e;
      this.addFormsStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  });

  @observable updateFormsStore = new AsyncStore();

  @action.bound
  updateForm = flow(function*(uuid, args) {
    this.updateFormsStore.loading = true;
    try {
      const response = yield this.fetch(
        `${process.env.REACT_APP_BASE_URL}/universal-form/${uuid}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(args),
        }
      ).then((res) => res.json());

      if (!response === true) {
        throw Error(response.error.Message);
      } else {
        this.updateFormsStore.data = response;
        this.updateFormsStore.loading = false;
        this.updateFormsStore.loaded = true;
        return response.data;
      }
    } catch (e) {
      console.log("e==>", e);
      this.updateFormsStore.error = e;
      this.updateFormsStore.loading = false;
      this.updateFormsStore.showError(this.errorFormatter("list", e));
      throw Error();
    }
  });

  async fetchMedicalConditions(tenantId) {
    try {
      this.loadingMedicalConditions = true;
      const response = await this.fetch(
        `${process.env.REACT_APP_FORMS_API_URL}/suggestions/medical-conditions`,
        {
          headers: {
            "x-custom-tenant-id": tenantId,
            "content-type": "application/json",
          },
        },
        tenantId,
        AUTHORIZATION_TYPE.TENANT
      ).then((res) => res.json());
      this.loadingMedicalConditions = false;
      if (!response.success) {
        throw Error(
          "An unexpected error occurred while attempting to fetch medical conditions"
        );
      } else {
        return response.data;
      }
    } catch (err) {
      this.loadingMedicalConditions = false;
      throw Error(
        "An unexpected error occurred while attempting to fetch medical conditions"
      );
    }
  }

  async addMedicalConditions(tenantId, medicalConditions) {
    try {
      const response = await this.fetch(
        `${process.env.REACT_APP_FORMS_API_URL}/suggestions/medical-conditions`,
        {
          method: "POST",
          headers: {
            "x-custom-tenant-id": tenantId,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            medicalConditions: medicalConditions,
          }),
        },
        tenantId,
        AUTHORIZATION_TYPE.TENANT
      ).then((res) => res.json());

      if (!response.success) {
        throw Error(
          "An unexpected error occurred while attempting to add new medical condition"
        );
      } else {
        return response.data;
      }
    } catch (err) {
      throw Error(
        "An unexpected error occurred while attempting to add new medical condition"
      );
    }
  }

  async deleteMedicalConditions(tenantId, medicalConditions) {
    try {
      const response = await this.fetch(
        `${process.env.REACT_APP_FORMS_API_URL}/suggestions/medical-conditions`,
        {
          method: "DELETE",
          headers: {
            "x-custom-tenant-id": tenantId,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            medicalConditions: medicalConditions,
          }),
        },
        tenantId,
        AUTHORIZATION_TYPE.TENANT
      ).then((res) => res.json());

      if (!response.success) {
        throw Error(
          "An unexpected error occurred while attempting to delete medical condition"
        );
      } else {
        return response.data;
      }
    } catch (err) {
      throw Error(
        "An unexpected error occurred while attempting to fetch medical condition"
      );
    }
  }

  async fetchReferredBy(tenantId) {
    try {
      this.loadingReferrals = true;
      const response = await this.fetch(
        `${process.env.REACT_APP_OFFICE_API_URL}/public/referrals?notPerson=1`,
        {
          headers: {
            "x-custom-tenant-id": tenantId,
            "content-type": "application/json",
          },
        },
        tenantId,
        AUTHORIZATION_TYPE.TENANT
      ).then((res) => res.json());
      this.loadingReferrals = false;
      if (!response.success) {
        throw Error(
          "An unexpected error occurred while attempting to fetch referrals list"
        );
      } else {
        return response.data;
      }
    } catch (err) {
      console.error(err.message);
      this.loadingReferrals = false;

      throw Error(
        "An unexpected error occurred while attempting to fetch referrals list"
      );
    }
  }
}
