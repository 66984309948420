import React, { useEffect, useState, useRef } from 'react';
import '../../pages/Core/Styles/index.css';
import { FormBuilder as FormIOBuilder, Components, Form } from '@formio/react';
import 'formiojs/dist/formio.builder.css';
import Button from '@material-ui/core/Button';
import { Scrollbars } from 'react-custom-scrollbars';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useStores } from "app/hooks/useStores";
import { Grid } from '@material-ui/core';
import FormPreview from './FormPreview';
import LoadJson from './LoadJson';
import { observer } from "mobx-react";

const UniversalFormBuilder = (props) => {
  let formSchema;
  const [importedSchemaEdit, setImportedSchemaEdit] = useState(props.importedSchema);
  const [importedSchema, setImportedSchema] = useState("");
  const [formDetails, setEditFormKey] = useState("");
  const [formName, setFormName] = useState("");
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isShowJsonLoader, setIsShowJsonLoader] = useState(false);
  const [isDisableButtons, setisDisableButtons] = useState(true);

  useEffect(() => {
    if (props.formDetails) {
      setisDisableButtons(false);
      if (props.formDetails.form_name) {
        setFormName(props.formDetails.form_name);
      }
      if (props.formDetails.form_json) {
        setImportedSchema(props.formDetails.form_json);
        setImportedSchemaEdit(props.formDetails.form_json);
      }
    }
    else {
      setImportedSchema({});
      setFormName("");
    }
  }, [props.formDetails])

  const [showNotification, setShowNotification] = useState(false);

  const {
    universalFormStore: { updateForm, addForm },
    notification,
  } = useStores();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(formSchema));
    setShowNotification(true);
  };

  const handleSaveForm = async () => {
    if (!formName || formName === "") {
      notification.showError(
        'Please enter form name.',
      );
    } else {
      let randomKey = (Math.random() + 1).toString(36).substring(2);
      let postData = {
        "id": formDetails ? formDetails.formName : `${formName.replace(/\s/g, '')}_${randomKey}`,
        "name": formName,
        "form": formSchema
      }

      if (props.formDetails) {
        await updateForm(props.formDetails.uuid, postData);
      }
      else
      {
        await addForm(postData);
      }
      notification.showSuccess('Universal form saved successfully');
      props.fetchData();
    }
  };

  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowNotification(false);
  };

  const handlePreviewForm = (event) => {
    setIsShowPreview(true);
  };

  const handleFormPreviewCallback = (isShowPreview) => {
    setIsShowPreview(isShowPreview);
  }

  const handleJsonLoad = (event) => {
    setIsShowJsonLoader(true);
  };

  const handleJsonLoadCallback = (importedSchemaEdit) => {
    setIsShowJsonLoader(false);
    if (importedSchemaEdit) {
      setisDisableButtons(false);
      setImportedSchema(importedSchemaEdit);
      setImportedSchemaEdit(importedSchemaEdit);
    }
  }
  
  return (
    <>
      <div className="form-root d-flex">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showNotification}
          onClose={handleNotificationClose}
          autoHideDuration={2000}
        >
          <MuiAlert elevation={6} variant="filled" severity="success">
            {' '}
            JSON Copied!
          </MuiAlert>
        </Snackbar>
        <div className="container FormBuilder">
          <Grid container spacing={4} className="mt-1">
            <Grid item md={3}>
              <Button
                variant="outlined"
                startIcon={<FileCopyIcon />}
                onClick={handleCopyToClipboard}
                disabled={isDisableButtons}
              >
                Copy JSON
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                onClick={handleJsonLoad}
              >
                Load JSON
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                onClick={handlePreviewForm}
                disabled={isDisableButtons}>
                Preview
              </Button>
            </Grid>
            <Grid item md={3}>
              <Button
                className="ml-2"
                variant="outlined"
                startIcon={<SaveIcon />}
                onClick={handleSaveForm}
                disabled={isDisableButtons}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {/* <h4 className="d-flex align-items-center justify-content-center" style={{ height: 40 }}>Form Builder</h4> */}
          <div className="mb-5 mt-5">
            <div class="form-group">
              <label>Form Name</label>
              <input type="text" name="formName" value={formName} maxLength={100} onChange={e => setFormName(e.target.value)} class="form-control" placeholder="Enter form name" />
            </div>
          </div>
          <Scrollbars
            style={{ height: '100%', borderRadius: '4px' }}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  backgroundColor: '#BBC1CD',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              />
            )}
          >
            <FormIOBuilder
              style={{ height: 'auto', overflowY: 'scroll' }}
              form={importedSchema}
              onChange={(json) => {
                formSchema = json;
              }}
              options={{
                builder: {
                  basic: {
                    components: {
                      toggleCustomComp: true,
                      buttonStyledRadioButton: true,
                      dialogCheckBox: true,
                    },
                  },
                },
              }}
            />
          </Scrollbars>
        </div>
      </div>
      {isShowPreview &&
        <FormPreview
          importedSchema={importedSchema}
          showPreview={isShowPreview}
          setIsShowPreview={setIsShowPreview}
          parentCallback={handleFormPreviewCallback} />
      }
      {isShowJsonLoader &&
        <LoadJson
          showJsonLoader={isShowJsonLoader}
          parentCallback={handleJsonLoadCallback} />
      }
    </>
  );
};

export default observer(UniversalFormBuilder);
