import { observable, flow } from "mobx";

export class Notification {
  @observable message = "";
  @observable severity = "info";
  @observable visible = false;
  autoHideDuration;
  anchorOrigin = { vertical: "top", horizontal: "right" };

  showInfo = flow(function* (message) {
    console.log("message", message);
    this.message = message;
    this.severity = "info";
    this.visible = true;
  });

  showError = flow(function* (message) {
    console.log("message", message);
    this.message = message;
    this.severity = "error";
    this.visible = true;
  });

  showSuccess = flow(function* (message) {
    console.log("message", message);
    this.message = message;
    this.severity = "success";
    this.visible = true;
  });

  showWarning = flow(function* (message) {
    console.log("message", message);
    this.message = message;
    this.severity = "warning";
    this.visible = true;
  });

  hideNotification = flow(function* () {
    // console.log(this)
    this.message = "";
    this.visible = false;
  });
}
