import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useObserver, Observer, useLocalStore } from "mobx-react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStores } from "app/hooks/useStores";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";

const headCells = [
  { id: "created_at", numeric: false, disablePadding: false, label: "Datetime" },
  { id: "action", numeric: false, disablePadding: false, sortable: false, label: "Action" },
  { id: "resource", numeric: false, disablePadding: false, sortable: false, label: "Resource" },
  { id: "created_by", numeric: false, disablePadding: false, sortable: false, label: "User" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default observer(function (props) {
  const { audit: { listAuditStore, listAudit } } = useStores();

  const classes = useStyles();

  const [orderData, setOrderData] = React.useState({
    sortCol: "created_at",
    sortDir: "desc"
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const { sortDir, sortCol } = orderData;
    const isAsc = sortCol === property && sortDir === "asc";
    setOrderData({
      sortCol: property,
      sortDir: (isAsc ? "desc" : "asc")
    });
  };

  useEffect(() => {
    const fetchData = () => {
      listAudit({
        ...orderData,
        rows: rowsPerPage,
        offset: rowsPerPage * page
      });
    }
    fetchData();
  }, [listAudit, orderData, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    console.log("handleChangePage", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    console.log("onChangeRowsPerPage", e);
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0);
  };

  const data = listAuditStore.data || { data: [], meta: {} }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title="Audit Logs"
          numSelected={0} loading={listAuditStore.loading} />
        {listAuditStore.loaded &&
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  headCells={headCells}
                  classes={classes}
                  numSelected={0}
                  order={orderData.sortDir}
                  orderBy={orderData.sortCol}
                  onRequestSort={handleRequestSort}
                  rowCount={data.data.length}
                />
                <TableBody>
                  {data.data.map((row, index) => {
                    return (
                      <>
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row}
                        >
                          <TableCell align="left">{row.created_at}</TableCell>
                          <TableCell align="left">{row.action}</TableCell>
                          <TableCell align="left">
                            {row.resource_id ?
                              <Link to={{
                                pathname: `/office/${row.resource_id}/edit`
                              }}>
                                {row.resource}
                              </Link>
                              : row.resource
                            }
                          </TableCell>
                          <TableCell align="left">{row.username}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.meta.ROW_COUNT || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }
      </Paper>
    </div>
  );
})