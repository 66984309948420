import React from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { useStores } from "app/hooks/useStores";

const Logout = function (props) {

  const { authentication } = useStores();

  React.useEffect(() => {
    authentication.logout();
  }, [])

  return Boolean(authentication.authenticatedData) ?
    <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
}

export default Logout;