import { observable, flow, action } from "mobx";
// import Fetcher from "../Fetcher";
import { serializeToQueryString } from "../../helpers/misc";
import PagingResource from "./utils/PagingResource";
import AsyncStore from "./utils/AsyncStore";
import CONSTANTS from "app/utils/constants";

export class Office extends PagingResource {
  @observable getOfficeStore = new AsyncStore();

  @action.bound
  getOffice = flow(function*({ id }) {
    this.getOfficeStore.reset();
    this.getOfficeStore.loading = true;
    try {
      var response = yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/office/${id}`
      ).then((r) => r.json());

      if (response.success === false) throw Error(response.error.Message);

      this.getOfficeStore.data = response.data;
      this.getOfficeStore.loading = false;
    } catch (e) {
      this.getOfficeStore.error = e;
      this.getOfficeStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  @observable createOfficeStore = new AsyncStore();

  @action.bound
  createOffice = flow(function*(params) {
    this.createOfficeStore.reset();
    this.createOfficeStore.loading = true;
    try {
      var result = yield this.fetch(`${CONSTANTS.ADMIN_API_URL}/office`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(params),
      }).then((r) => r.json());

      if (result.success === false) throw Error(result.error.Message);

      this.createOfficeStore.loading = false;
    } catch (e) {
      this.createOfficeStore.error = e;
      this.createOfficeStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  @observable updateOfficeStore = new AsyncStore();

  @action.bound
  updateOffice = flow(function*(params) {
    this.updateOfficeStore.reset();
    this.updateOfficeStore.loading = true;
    try {
      var result = yield this.fetch(`${CONSTANTS.ADMIN_API_URL}/office`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(params),
      }).then((r) => r.json());

      if (result.success === false) throw Error(result.error.Message);

      this.updateOfficeStore.loading = false;
    } catch (e) {
      this.updateOfficeStore.error = e;
      this.updateOfficeStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  @observable listOfficeStore = new AsyncStore();

  @action.bound
  listOffice = flow(function*(params) {
    // this.listOfficeStore.reset();
    this.listOfficeStore.loading = true;
    try {
      var queryString = serializeToQueryString(params);
      const response = yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/office${queryString}`
      ).then((r) => r.json());

      if (response.success === false) throw Error(response.error.Message);

      this.listOfficeStore.data = response;
      this.listOfficeStore.loading = false;
      this.listOfficeStore.loaded = true;
    } catch (e) {
      this.listOfficeStore.error = e;
      this.listOfficeStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  @observable syncOfficeStore = new AsyncStore();

  @action.bound
  syncOffice = flow(function*({ id }) {
    this.params = { id };
    this.syncOfficeStore.loading = true;
    try {
      yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/seeding/office/${id}`
      ).then((r) => r.json());

      this.syncOfficeStore.loading = false;
      this.syncOfficeStore.loaded = true;
    } catch (e) {
      this.syncOfficeStore.error = e;
      this.syncOfficeStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  async listApiHandler(params) {
    var queryString = serializeToQueryString(params);
    const response = await this.fetch(
      `${CONSTANTS.ADMIN_API_URL}/office${queryString}`
    ).then((r) => r.json());
    return response.data;
  }

  async createApiHandler(args) {
    console.log("createAPIhandler", args);
    try {
      const data = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(args),
      }).then((r) => console.log("r", r));
      return data;
    } catch (e) {
      throw e;
    }
  }

  async updateApiHandler(params) {
    console.log("updateApiHandler", params);
    try {
      const data = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(params),
      }).then((r) => console.log("r", r));
      return data;
    } catch (e) {
      throw e;
    }
  }

  async getApiHandler({ id }) {
    const response = await this.fetch(
      `${CONSTANTS.ADMIN_API_URL}/office/${id}`
    ).then((r) => r.json());
    return response.data;
  }

  async updateAPIhandler(args) {
    const data = await this.fetch(this.resourceUrl + `/${args.id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(args),
    }).then((r) => r.json());
    return data;
  }

  async deleteAPIhandler(args) {
    console.log("args", args);
    try {
      const data = await this.fetch(this.resourceUrl + `/${args.id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(args),
      }).then((r) => r.json());
      return data;
    } catch (e) {
      throw e;
    }
  }
  messageFormatter(type, results) {
    switch (type) {
      case "update":
        return "Account updated successfully";
      case "create":
        return "Account create successfully";
      case "delete":
        return "Account deleted successfully";
    }
  }
  errorFormatter(type, e) {
    console.log(e);
    return e.message;
  }

  @observable listOfficeNumberStore = new AsyncStore();

  @action.bound
  listOfficeNumber = flow(function*(officeId, params) {
    // this.listOfficeNumberStore.reset();
    this.listOfficeNumberStore.loading = true;
    try {
      var queryString = serializeToQueryString(params);
      const response = yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/office/number/${officeId}${queryString}`
      ).then((r) => r.json());

      if (response.success === false) throw Error(response.error.Message);

      this.listOfficeNumberStore.data = response;
      this.listOfficeNumberStore.loading = false;
      this.listOfficeNumberStore.loaded = true;
    } catch (e) {
      this.listOfficeNumberStore.error = e;
      this.listOfficeNumberStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  @observable listFormsStore = new AsyncStore();

  @action.bound
  listOfForms = flow(function*(officeId, param) {
    var queryString = serializeToQueryString(param);

    this.listFormsStore.loading = true;
    try {
      const response = yield this.fetch(
        `${process.env.REACT_APP_BASE_URL}/office/list-forms/${officeId}${queryString}`
      ).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        this.listFormsStore.data = response;
        this.listFormsStore.loading = false;
        this.listFormsStore.loaded = true;
        return response.data;
      }
    } catch (e) {
      console.log("e==>", e);
      this.listFormsStore.error = e;
      this.listFormsStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  });
  
  @observable formByKeyStore = new AsyncStore();

  @action.bound
  formByKey = flow(function*(officeId, param) {
    this.formByKeyStore.loading = true;
    try {
      var queryString = serializeToQueryString(param);
      const response = yield this.fetch(
        `${process.env.REACT_APP_BASE_URL}/office/list-forms/${officeId}${queryString}`
      ).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        this.formByKeyStore.data = response;
        this.formByKeyStore.loading = false;
        this.formByKeyStore.loaded = true;
        return response.data;
      }
    } catch (e) {
      console.log("e==>", e);
      this.formByKeyStore.error = e;
      this.formByKeyStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  });

  @observable addFormsStore = new AsyncStore();

  @action.bound
  addUpdateForms = flow(function*(officeId, args) {
    this.addFormsStore.loading = true;
    try {
      const response = yield this.fetch(
        `${process.env.REACT_APP_BASE_URL}/office/add-form/${officeId}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(args),
        }
      ).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        this.addFormsStore.data = response;
        this.addFormsStore.loading = false;
        this.addFormsStore.loaded = true;
        return response.data;
      }
    } catch (e) {
      console.log("e==>", e);
      this.addFormsStore.error = e;
      this.addFormsStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  });

  @observable deleteFormStore = new AsyncStore();

  @action.bound
  deleteForm = flow(function*(officeId, formId, archive) {
    this.deleteFormStore.loading = true;
    try {
      const response = yield this.fetch(
        `${process.env.REACT_APP_BASE_URL}/office/archive-form/${officeId}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ formId, archive }),
        }
      ).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        this.deleteFormStore.data = response;
        this.deleteFormStore.loading = false;
        this.deleteFormStore.loaded = true;
        return response.data;
      }
    } catch (e) {
      console.log("e==>", e);
      this.deleteFormStore.error = e;
      this.deleteFormStore.loading = false;
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  });
}
