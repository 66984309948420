import React, { useEffect, useState } from "react";
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import './formSettings.css';
import { FormBuilder as FormIOBuilder, Components, Form } from '@formio/react';
import Scrollbars from "react-custom-scrollbars";
import Modal from "../Core/Modal";

const FormPreview = (props) => {

    const [showPreview, setShowPreview] = useState();

    useEffect(() => {
        if (props.showPreview) {
            setShowPreview(props.showPreview);
        }
    }, [props.showPreview]);

    const handlePreviewFormClose = (event) => {
        setShowPreview(false);
        props.parentCallback(false);
    };

    return (
        <>
            {showPreview &&
                <Popper
                    open={props.showPreview}
                    anchorEl={undefined}
                    role={undefined}
                    transition
                    disablePortal
                    placement="bottom-start"
                    style={{ zIndex: 99 }}
                >
                    <Modal elevation={8} 
                        size="md"
                        body={
                            <div id="formPreview">
                                <Form
                                    src={props.importedSchema}
                                />
                            </div>
                        }
                        onClose={handlePreviewFormClose}
                    />
                </Popper>
            }
        </>
    );
};
export default FormPreview;