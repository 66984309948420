import React from "react";
import { configure } from "mobx";
import "mobx-react/batchingForReactDom";
import { Notification } from "./notification";
import { Authentication } from "./authentication";
import { Office } from "./offices.store";
import Audit from "./audit.store";
import OfficeStore from "./office";
import { IPManagenemt } from "./ipManagement.store";
import { OriginationDomain } from "./orginationDomain.store";
import UniversalFormStore from "./universalForms.store"; 


configure({
  // computedRequiresReaction: true,
  enforceActions: "observed",
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
});

class RootStore {
  constructor() {
    this.notification = new Notification(this);
    this.authentication = new Authentication(this);
    this.office = new Office(this);
    this.audit = new Audit(this);
    this.officeStore = new OfficeStore(this);
    this.ipManagement = new IPManagenemt(this);
    this.originationDomain = new OriginationDomain(this);
    this.universalFormStore = new UniversalFormStore(this);
  }
}
const store = new RootStore();

export const storesContext = React.createContext(store);
