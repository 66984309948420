import React, {useEffect} from 'react'
import { Formik, Field, Form } from "formik";
import TextInputField from "app/components/formik/TextInputField";
import { Grid, Button } from "@material-ui/core";



const SearchOffice = ({setSearchVal, loadingStatus}) => {
  return (
    <>
    <Formik
    initialValues={{ searchofc: "" }}
    onSubmit={(values, actions) => {
      setSearchVal(values.searchofc)
    }}
    validator={() => ({})}
    >
    {() => (
      <Form>
      <Grid container style={{marginBottom: '30px'}}>
        <Grid item xs={6} style={{marginLeft: '20px', display: "flex"}}>
        <TextInputField
            fieldLabel=""
            fieldName="searchofc"
            type="text"
            placeholder="Search An Office"
            disabled={loadingStatus}
        />
        </Grid>
        <Grid item alignItems="stretch" style={{ display: "flex", marginLeft: '20px' }}>
          <Button type="submit" color="secondary" variant="contained" disabled={loadingStatus}>
          {loadingStatus ? `Searching...` : `Search`}
          </Button>
        </Grid>
        </Grid>
        </Form>
    )}
    </Formik>
    </>
  )
}

export default SearchOffice