/**
 * Entry application component used to compose providers and render Routes.
 * */
import "./utils/custom-fetch"
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { withLDProvider } from 'launchdarkly-react-client-sdk';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App({ basename }) {
  // <MobxProvider authStore={AuthStore}>
  return (
    <QueryClientProvider client={queryClient}>
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          <MaterialThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */}
              <Routes />
            </I18nProvider>
          </MaterialThemeProvider>
        </BrowserRouter>
      </React.Suspense>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_KEY,
})(App);
