const CONSTANTS = {
  ADMIN_API_URL: process.env.REACT_APP_BASE_URL,
  FORMS_API_URL: process.env.REACT_APP_FORMS_API_URL,
  COGNITO_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID,
  COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  OFFICE_API_URL:process.env.REACT_APP_OFFICE_API_URL,
  ENV: process.env.REACT_APP_ENV
};

const AUTHORIZATION_TYPE = Object.freeze({
  USER: 'user',
  PATIENT: 'patient',
  TENANT: 'tenant',
  ADMIN: 'admin'
});

export default CONSTANTS;
export {
  AUTHORIZATION_TYPE,
};