import { observer } from "mobx-react";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { useStores } from "app/hooks/useStores";
import IPModal from "app/components/IPModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AlertDialog from "app/components/Core/AlertDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}));

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "ip-address",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "IP Address",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: "Action",
  },
];

function IPManagement() {
  const { ipManagement, notification } = useStores();
  const { getIpList, deleteIpAddress } = ipManagement;
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const { data, isFetching } = useQuery(["ipList"], getIpList);
  const listData = data || [];

  const queryClient = useQueryClient();

  const deleteIpAddressMutation = useMutation((id) => deleteIpAddress(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("ipList");
      notification.showSuccess(`IP Addresses was deleted successfully`);
    },
    onError: (error) => {
      notification.showError(
        "An unexpected error occurred while attempting to delete the IP Address"
      );
    },
    onSettled: () => {
      setSelectedRow({});
    },
  });

  const handleDelete = () => {
    setShowAlert(false);
    deleteIpAddressMutation.mutateAsync(selectedRow.id);
  };

  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <EnhancedTableToolbar
          title="IP Management"
          numSelected={0}
          loading={isFetching || deleteIpAddressMutation.isLoading}
        />
        {listData.length < 1 && (
          <div>
            <Button
              disabled={isFetching || deleteIpAddressMutation.isLoading}
              startIcon={<AddIcon />}
              variant="contained"
              disableElevation
              size="large"
              color="secondary"
              className="mr-3"
              onClick={() => setShowAddModal(true)}
            >
              Add
            </Button>
          </div>
        )}
      </div>

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            headCells={headCells}
            classes={classes}
            numSelected={0}
            rowCount={listData.length}
          />

          <TableBody>
            {listData?.length > 0 &&
              listData?.map((row) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableRow hover tabIndex={-1} key={row}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.ip_address}</TableCell>
                      <TableCell align="left" scope="row">
                        <Tooltip title="Edit" aria-label="edit">
                          <IconButton
                            disabled={
                              isFetching || deleteIpAddressMutation.isLoading
                            }
                            onClick={() => {
                              setSelectedRow(row);
                              setShowAddModal(true);
                            }}
                            color="secondary"
                            aria-label="Edit"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Delete" aria-label="delete">
                          <IconButton
                            disabled={
                              isFetching || deleteIpAddressMutation.isLoading
                            }
                            onClick={() => {
                              setSelectedRow(row);
                              setShowAlert(true);
                            }}
                            color="secondary"
                            aria-label="Delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip> */}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {showAddModal && (
        <IPModal
          onClose={() => setShowAddModal(false)}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}
      {setShowAlert && (
        <AlertDialog
          open={showAlert}
          onConfirm={handleDelete}
          onClose={() => setShowAlert(false)}
          title="Are you sure?"
          message="Are you sure you want to permanently delete this IP Address?"
        />
      )}
    </Paper>
  );
}

export default observer(IPManagement);
