import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import Log from "./pages/Log";
import Notification from "./utils/notification";
import Account from "./pages/office/Account";
import AuditLog from "./pages/AuditLog";
import IPManagement from "./pages/IPManagement";
import OriginationDomain from "./pages/OriginationDomain";
import UniversalForm from "./pages/UniversalForm";

const Create = lazy(() => import("./pages/office/Create"));
const EditOffice = lazy(() => import("./pages/office/EditOffice"));

function Content(props) {
  return (
    <>
      <ContentRoute {...props} />
      <Notification />
    </>
  );
}

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/office" />
        <Content exact path="/office/create" component={Create} />
        <Content path="/office/:id/edit" component={EditOffice} />
        <Content path="/auditlog" component={AuditLog} />
        <Content path="/ip-management" component={IPManagement} />
        <Content path="/origination-domain" component={OriginationDomain} />
        <Content path="/logs" exact component={Log} />
        <Content path="/office" exact component={Account} />
        <Content path="/builder" component={BuilderPage} />
        <Content path="/universalForm" component={UniversalForm} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
