import Resource from "./utils/resource";
import { observable, action } from "mobx";
import CONSTANTS from "app/utils/constants";

export default class OfficeStore extends Resource {
  @observable selectedOfficeDetails = null;

  @action
  setSelectedOfficeDetails(data) {
    this.selectedOfficeDetails = { ...data };
  }

  @action
  resetSelectedOfficeDetails(officeId) {
    this.selectedOfficeDetails = null;
  }

  /**
   * Get office details by office id
   * @param {Number} officeId
   */
  async getOfficeDetails(officeId) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/${officeId}`).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  // The object transformation is required due to input limitations of create/update office API
  transformData(data) {
    const { title, firstname: firstName, lastname: lastName, phone_no: phoneNo, office_no: officeNo, address, status, email, dbname: dbName, apikey: apiKey, url, client_id: clientId, user_pool_id: userPoolId, tenant_id: tenantId, timezone, telnyx_number: telnyxNumber, provider_fax_did: providerFaxDid, telnyx_connection_id: telnyxConnectionId, local_server_app_key: localServerAppKey, provider_account_sid: providerAccountSid, provider_auth_token: providerAuthToken, provider_sip_domain: providerSipDomain, provider_api_key: providerApiKey, provider_api_secret: providerApiSecret, provider_app_sid: providerAppSid, documo_key: documoKey, trunk_sid: trunkSid } = data;
    return {
      title,
      firstName,
      lastName,
      phoneNo,
      officeNo,
      address,
      status,
      email,
      url,
      dbName,
      apiKey,
      timezone,
      telnyxNumber,
      telnyxConnectionId,
      providerFaxDid,
      providerAccountSid,
      providerAuthToken,
      providerSipDomain,
      providerApiKey,
      providerApiSecret,
      providerAppSid,
      localServerAppKey,
      tenantId,
      clientId,
      userPoolId,
      documoKey,
      trunkSid,
    };
  }

  /**
   * Create new office
   * @param {Object} officeDetails
   */
  async createOffice(officeDetails) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(this.transformData(officeDetails)),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Update office details
   * @param {Number} officeId
   * @param {Object} officeDetails
   */
  async updateOffice(officeId, officeDetails) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/${officeId}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(this.transformData(officeDetails)),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Get office users
   * @param {Number} officeId
   * @param {Object} tableConfig
   */
  async getOfficeUsers(officeId, tableConfig) {
    try {
      const params = {
        rows: tableConfig.rowsPerPage,
        offset: tableConfig.page * tableConfig.rowsPerPage,
        sortCol: tableConfig.sortCol,
        sortDir: tableConfig.sortDir,
      };

      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/users/${officeId}?${new URLSearchParams(params).toString()}`).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Create Database for given office id
   * @param {Number} officeId
   */
  async createDatabase(officeId) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/create-database`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ office_id: officeId }),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Chech for database status
   * @param {Number} officeId
   */
  async checkDatabaseStatus(officeId) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/config/${officeId}`).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Create user pool id
   * @param {Number} officeId
   */
  async createUserPoolId(officeId) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/create-user-pool`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ office_id: officeId }),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Create Local Server App Key
   * @param {Number} officeId
   */
  async createLocalServerAppKey(officeId) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/create-local-server-appkey`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ office_id: officeId }),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data.id;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Create SQS APP URL
   * @param {*} officeId
   */
  // async createSQSAppUrl(officeId) {
  //     try {
  //         const response = await this.fetch(
  //             `${CONSTANTS.ADMIN_API_URL}/office/create-sqs-queue`,
  //             {
  //                 method: "POST",
  //                 headers: {
  //                     "content-type": "application/json",
  //                 },
  //                 body: JSON.stringify({ office_id: officeId })
  //             }
  //         ).then((res) => res.json());

  //         if (!response.success) {
  //             throw Error(response.error.Message);
  //         } else {
  //             return response.data.QueueUrl;
  //         }
  //     } catch (e) {
  //         this.notification.showError(this.errorFormatter("list", e));
  //         throw Error();
  //     }
  // }

  /**
   * Invite user
   * @param {Object} userDetails
   */
  async inviteUser(userDetails) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/invite-user/${this.selectedOfficeDetails.id}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(userDetails),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.data.errorMessage);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }

  /**
   * Invite user
   * @param {Object} userDetails
   */
  async resendInviteUser(userDetails) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/resend-invite/${this.selectedOfficeDetails.id}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(userDetails),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.data.errorMessage);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }

  /**
   * Add number
   * @param {Object} numberDetails
   */
  async addNumber(numberDetails) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/number`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(numberDetails),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.data.errorMessage);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }
  /**
   * Update number
   * @param {Object} numberDetails
   */
  async updateNumber(numberDetails) {
    try {
      const { numberType, officeId, officeNumber, providerId } = numberDetails;
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/number/${numberDetails.id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          numberType,
          officeId,
          officeNumber,
          providerId,
        }),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.data.errorMessage);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }
  async updateMigration({ id, ...rest }) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/migrate/office/number/${id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(rest),
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.data.errorMessage);
      } else {
        return response.data;
      }
    } catch (e) {
      throw Error(e);
    }
  }
  async migrateOffice(id) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/migrate/office/${id}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.data.errorMessage);
      } else {
        return response.data;
      }
    } catch (e) {
      throw Error(e);
    }
  }
  async migrateSipPeers(id) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/migrate/sip-peers/${id}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.data.errorMessage);
      } else {
        return response.data;
      }
    } catch (e) {
      throw Error(e);
    }
  }
  async deleteSipPeers(id) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/migrate/sip-peers/${id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
        },
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.data.errorMessage);
      } else {
        return response.data;
      }
    } catch (e) {
      throw Error(e);
    }
  }

  /**
   * Delete number
   */
  async deleteNumber(id) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/number/${id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
        },
      }).then((res) => res.json());

      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Get providers
   */
  async getProviders() {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/providers`).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Create Twilio Sub-Account
   */
  async createSubAccount(params) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/twilio`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Delete Twilio Sub-Account
   */
  async deleteSubAccount(id) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/twilio/${id}`, {
        method: "DELETE",
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Get Available Twilio Numbers
   */
  async getAvailableNumbers(params) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/twilio/numbers`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Buy Number(s) from twilio
   */
  async buyNumberFromTwilio(params) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/office/twilio/buy-number`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error();
    }
  }

  /**
   * Get all hardware phones
   */
  async getHardwarePhones(officeID) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/hardware-phone/${officeID}?hardwarePhone=true`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }

  /**
   * Get hardware phone details by ID
   */
  async getHardwarePhone({ officeID, phoneID }) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/hardware-phone/${officeID}/${phoneID}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }

  /**
   * Get office agents
   */
  async getOfficeAgents(officeID) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/hardware-phone/${officeID}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }

  /**
   * Add hardware phone
   */
  async addHardwarePhone({ officeID, data }) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/hardware-phone/${officeID}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }

  /**
   * Update hardware phone
   */
  async updateHardwarePhone({ officeID, phoneID, config }) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/hardware-phone/${officeID}/${phoneID}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(config),
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }

  /**
   * Delete hardware phone
   */
  async deleteHardwarePhone({ officeID, phoneID, macAddress }) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/hardware-phone/${officeID}/${phoneID}${macAddress ? `?mac_address=${macAddress}`: ''}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }

  /**
   * Reboot hardware phone
   */
  async rebootHardwarePhone({ officeID, phoneID }) {
    try {
      const response = await this.fetch(`${CONSTANTS.ADMIN_API_URL}/hardware-phone/${officeID}/reboot/${phoneID}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());
      if (!response.success) {
        throw Error(response.error.Message);
      } else {
        return response.data;
      }
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
      throw Error(e);
    }
  }
}
