import { observable, flow, action } from "mobx";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import Axios from "axios";
import AsyncStore from "./utils/AsyncStore";
import CONSTANTS from "app/utils/constants";

export class Authentication {
    // @observable authToken = null; // = localStorage.getItem('authToken');
    @observable workspace = null;
    // @observable user; // = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    @observable authenticatedData = null;
    @observable loadedAuth = false;
    userPoolInst = null;

    constructor(store) {
        this.init();
        this.notification = store.notification;
        this.store = store;
    }

    init = function () {
        this.authenticatedData = JSON.parse(localStorage.getItem("token"));
        this.loadedAuth = true;
        this.workspace = {
            UserPoolId: CONSTANTS.COGNITO_POOL_ID,
            ClientId:CONSTANTS.COGNITO_CLIENT_ID
        };
        this.userPoolInst = new AmazonCognitoIdentity.CognitoUserPool(this.workspace);
    }

    @observable loginStore = new AsyncStore();

    @action.bound
    login = flow(function* ({ email, password }) {
        this.loginStore.loading = true;
        try {
            this.authenticatedData = yield this._loginApiHandler({
                username: email,
                password,
                userPoolInst: this.userPoolInst,
            });
            localStorage.setItem("token", JSON.stringify(this.authenticatedData));

            // if (result.success === false) throw Error(result.error.Message);

            this.loginStore.loading = false;
        } catch (e) {
            this.loginStore.error = e;
            this.loginStore.loading = false;
            this.notification.showError(e.message);
        }

        // this.loginStore.loading = true;
        // console.log(email, password, this);
        // try {
        //     this.authenticatedData = yield this._loginApiHandler({
        //         username: email,
        //         password,
        //         userPoolInst: this.userPoolInst,
        //     });
        //     localStorage.setItem("token", JSON.stringify(this.authenticatedData));
        //     this.loginStore.loading = false;
        // } catch (e) {
        //     this.loginStore.loading = false;
        // }
        // return this.authenticatedData;
    });

    logout = flow(function* (args) {
        this.authenticatedData = null;
        localStorage.clear();
    });

    forgotPassword = flow(function* () { });

    assignWorkSpace = flow(function* (args) {
        const response = yield this._getTenantApiHandler({
            tenantId: args.workspace,
        });
        this.workspace = {
            ...response,
            tenantId: args.workspace,
        };
        localStorage.setItem("tenant", JSON.stringify(this.workspace));
        this._initUserPool();
        return this.workspace;
    });

    forcePasswordChange = flow(function* ({ newPassword, oldPassword }) {
        let poolData = {
            UserPoolId: CONSTANTS.COGNITO_POOL_ID,
            ClientId: CONSTANTS.COGNITO_CLIENT_ID
        };
        new AmazonCognitoIdentity.CognitoUserPool(poolData).changePassword({
            newPassword,
            oldPassword
        });
    });

    async forcePasswordChangeApiHandler({
        userPoolInst,
        newPassword,
        oldPassword
    }) {

    }

    async _getTenantApiHandler({ tenantId }) {
        return fetch(
            CONSTANTS.ADMIN_API_URL + "/awscredential/" + tenantId
        ).then((r) => r.json());
    }

    _loginApiHandler = async ({ username, password, userPoolInst }) => {
        let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            { Username: username, Password: password }
        );

        let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: username, Pool: userPoolInst,
        });

        const authenticatedData = await new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => resolve(result),
                onFailure: (err) => reject(err),
            });
        });

        // console.log("authenticatedData", authenticatedData);

        let result = await Axios({
            url: `${CONSTANTS.ADMIN_API_URL}/user/me`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${authenticatedData.accessToken.jwtToken}`,
                AuthorizationType: 'admin',
                'Content-Type': 'application/json'
            }
        }).then((r) => r.data);

        // console.log("user/me", result);

        return {
            data: result.data,
            ...authenticatedData
        };
    };
}
