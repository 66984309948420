import CONSTANTS from "app/utils/constants";
import { action, flow } from "mobx";
import PagingResource from "./utils/PagingResource";

export class IPManagenemt extends PagingResource {
  @action.bound
  getIpList = flow(function*() {
    try {
      var response = yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/server/ip/address`
      ).then((r) => r.json());

      if (response.success === false) throw Error(response.error.Message);
      return response.data;
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  @action.bound
  addIpAddress = flow(function*(params) {
    try {
      var response = yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/server/ip/address`,
        {
          method: "POST",
          body: JSON.stringify(params),
          headers: { "Content-Type": "application/json" },
        }
      ).then((r) => r.json());

      if (response.success === false) throw Error(response.error.Message);
      return response.data;
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  @action.bound
  updateIpAddress = flow(function*(data) {
    const { id, name, ip_address } = data;
    try {
      var response = yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/server/ip/address/${id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name, ip_address }),
        }
      ).then((r) => r.json());
      if (response.success === false) throw Error(response.error.Message);
      return response.data;
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
    }
  });

  @action.bound
  deleteIpAddress = flow(function*(id) {
    try {
      var response = yield this.fetch(
        `${CONSTANTS.ADMIN_API_URL}/server/ip/address/${id}`,
        {
          method: "DELETE",
        }
      ).then((r) => r.json());
      if (response.success === false) throw Error(response.error.Message);
      return response.data;
    } catch (e) {
      this.notification.showError(this.errorFormatter("list", e));
    }
  });
}
