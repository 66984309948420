import axios from "axios";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import CONSTANTS from "app/utils/constants";

export const LOGIN_URL = `${CONSTANTS.ADMIN_API_URL}/login`;
export const REGISTER_URL = "/register";
export const REQUEST_PASSWORD_URL = "/forgot-password";

export const ME_URL = `${CONSTANTS.ADMIN_API_URL}/me`;

export const login = async (email, password) => {
  console.log("email", email);

  if (email && password) {
    const result = await new Promise((resolve, reject) => {
      let poolData = {
        UserPoolId: CONSTANTS.COGNITO_POOL_ID,
        ClientId: CONSTANTS.COGNITO_CLIENT_ID,
      };

      let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

      let authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails({
          Username: email,
          Password: password,
        });
      let userData = {
        Username: email,
        Pool: userPool,
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => reject(err),
      });
    });
    console.log(result);
    const Result = await fetch(ME_URL, {
      method: "POST",
      headers: {
        accept: "*/*",
        "accept-language": "en-GB,en-US;q=0.9,en;q=0.8",
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
      },
      body: JSON.stringify({ username: email }),
    }).then((r) => r.json());
    return {
      data: Result,
      ...result.accessToken,
    };
  }
};

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
