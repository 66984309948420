import { Button } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import Modal from "../Core/Modal";
import TextInputField from "../formik/TextInputField";
import * as Yup from "yup";
import { useStores } from "app/hooks/useStores";
import { useMutation, useQueryClient } from "react-query";

const DomainModal = ({ onClose, selectedRow, setSelectedRow }) => {
  const { originationDomain, notification } = useStores();
  const { addDomain, updateDomain } = originationDomain;
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
    setSelectedRow({});
  };

  const isEditing = Object.keys(selectedRow).length > 0;

  const initialValues = useMemo(() => {
    const { name, domain } = selectedRow;
    return isEditing ? { name, domain } : { name: "", domain: "" };
  }, [selectedRow]); // eslint-disable-line react-hooks/exhaustive-deps

  const addDomainMutation = useMutation((data) => addDomain(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("domainList");
      notification.showSuccess(`Domain was added successfully`);
      handleClose();
    },
    onError: (error) => {
      notification.showError(
        "An unexpected error occurred while attempting to add the Domain"
      );
    },
  });

  const updateDomainMutation = useMutation((data) => updateDomain(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("domainList");
      notification.showSuccess(`Domain was updated successfully`);
      handleClose();
    },
    onError: (error) => {
      notification.showError(
        "An unexpected error occurred while attempting to update the Domain"
      );
    },
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .max(56),
    domain: Yup.string()
      .required("Domain is required")
      .matches(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        "Not a Domain"
      )
      .max(100),
  });

  const handleSubmit = ({ name, domain }) => {
    const data = { name, domain };
    isEditing
      ? updateDomainMutation.mutateAsync({ id: selectedRow.id, ...data })
      : addDomainMutation.mutateAsync(data);
  };

  return (
    <Modal
      size="sm"
      header={isEditing ? "Edit Domain" : "Add Domain"}
      onClose={handleClose}
      body={
        <div>
          <div className="d-flex flex-column justify-content-center">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({ isSubmitting }) => (
                <Form>
                  <label className="required mt-4 mb-2">Name</label>
                  <TextInputField
                    fieldName="name"
                    disabled={isSubmitting}
                    maxLength={56}
                    placeholder="Enter Name"
                    required
                  />
                  <label className="required mt-4 mb-2">Domain</label>
                  <TextInputField
                    fieldName="domain"
                    disabled={isSubmitting}
                    maxLength={100}
                    placeholder="Enter Domain"
                    required
                  />
                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      className="primary-btn mr-auto"
                      variant="outlined"
                      disabled={isSubmitting}
                      disableElevation
                      color="primary"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="secondary-btn"
                      variant="contained"
                      disabled={isSubmitting}
                      disableElevation
                      color="secondary"
                      style={{ width: "auto" }}
                    >
                      {isEditing ? "Update" : "Add"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      }
    />
  );
};

export default DomainModal;
