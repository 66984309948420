import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { useObserver } from "mobx-react";

import Snackbar from '@material-ui/core/Snackbar';
import { useStores } from 'app/hooks/useStores';

export default function () {
    const { notification } = useStores();

    return useObserver(() => (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={notification.visible}
            autoHideDuration={6000}
            onClose={() => notification.hideNotification()}>
            <Alert severity={notification.severity} >
                {notification.message}
            </Alert>
        </Snackbar>
    ))
}