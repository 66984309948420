import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
} from "@material-ui/core";
import { observer } from "mobx-react";
import { useStores } from "app/hooks/useStores";
import { Scrollbars } from 'react-custom-scrollbars';
import UniversalFormBuilder from "app/components/UniversalForm/UniversalFormBuilder";
import styles from "./index.module.css"
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Spinner from "react-bootstrap/Spinner";
import "../../pages/Core/Styles/index.css";

const UniversalFormList = () => {
  const {
    universalFormStore: {
      listFormsStore,
      listOfForms,
    },
    universalFormStore,
  } = useStores();

  const [formDetails, setFormDetails] = useState(null);
  const [importedSchema, setImportedSchema] = useState({});

  const fetchData = () => {
    listOfForms(universalFormStore.listOfForms);
  };

  const handleListItemClick = (row) => {
    setFormDetails(row);
    setImportedSchema(row.form_json);
  };
  const handleClearForm = () => {
    setFormDetails(null);
    setImportedSchema({});
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfForms]);

  const data = listFormsStore?.data || { data: [], meta: {} };

  return (
    <div className={styles.root}>
      <Grid container spacing={1} >
        <Grid item xs={6} md={3} className={styles.allSectionsContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Forms</div>
          </div>
          <div className={styles.sectionContainer}>
            <Scrollbars>
              <MenuList className="ml-auto mr-2">
                {listFormsStore.loaded &&
                  <>
                    {data?.data.map((row) => (
                      <MenuItem
                        onClick={() => handleListItemClick(row)}
                        id={row.form_key}
                        style={{whiteSpace: 'normal'}}>
                        <span>{row.form_name}</span>
                      </MenuItem>))}
                  </>
                }
                {listFormsStore.loading &&
                  <>
                    <Spinner className="ml-4" animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </>
                }
              </MenuList>
            </Scrollbars>
          </div>
        </Grid>
        <Grid item xs={6} md={9} className={styles.allSectionsContainer}>
          <Grid spacing={2} className={styles.headerContainer}>
            <Grid item xs={6} md={9} className={styles.headerText}>Form Builder</Grid>
            <Grid item md={2} style={{ textAlign: 'right' }}>
              <Button
                className={styles.formButton}
                onClick={() => handleClearForm()}>
                  New Form
              </Button></Grid>
          </Grid>
          <div className="container formBuilder">
            <UniversalFormBuilder
              fetchData={fetchData}
              formDetails={formDetails}
              importedSchema={importedSchema}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
};

export default observer(UniversalFormList);
