import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useObserver } from "mobx-react";
import { Link } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react-lite";
import EnhancedTableHead from "../EnhancedTableHead";
import EnhancedTableToolbar from "../EnhancedTableToolbar";
import SearchOffice from "./SearchOffice"; 

const headCells = [
  { id: "tenant_id", numeric: false, disablePadding: false, label: "Tenant ID" },
  { id: "firstname", numeric: false, disablePadding: false, label: "First Name" },
  { id: "lastname", numeric: false, disablePadding: false, label: "Last Name" },
  {
    id: "telnyx_number",
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: "Kasper (Telnyx) Phone Number",
  },
  {
    id: "timezone",
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: "Timezone",
  },
  {
    id: "status",
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: "status",
  },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: "Actions",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default observer(function (props) {
  const { office: { listOfficeStore, listOffice, syncOfficeStore, syncOffice } } = useStores();
  const classes = useStyles();

  const [orderData, setOrderData] = React.useState({
    sortCol: "tenant_id",
    sortDir: "asc"
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState("");
  const [loadingPage, setLoadingPage] = React.useState();

  const handleRequestSort = (event, property) => {
    const { sortDir, sortCol } = orderData;
    const isAsc = sortCol === property && sortDir === "asc";
    setOrderData({
      sortCol: property,
      sortDir: (isAsc ? "desc" : "asc")
    });
  };

  useEffect(() => {
    setLoadingPage(listOfficeStore.loading || syncOfficeStore.loading);
  }, [listOfficeStore.loading, syncOfficeStore.loading])
  
  useEffect(() => {
    const fetchData = () => {
      listOffice({
        ...orderData,
        rows: rowsPerPage,
        offset: rowsPerPage * page,
        search: search
      });
    }
    fetchData();
  }, [listOffice, orderData, page, rowsPerPage, search]);


  const handleChangePage = (event, newPage) => {
    console.log("handleChangePage", newPage);
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    console.log("onChangeRowsPerPage", e);
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0);
  };

  const data = listOfficeStore.data || { data: [], meta: {} }

  return useObserver(() => (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title="Office List"
          numSelected={0} loading={listOfficeStore.loading || syncOfficeStore.loading} />
          <SearchOffice setSearchVal={setSearch} loadingStatus={loadingPage} />
        {listOfficeStore.loaded && (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  headCells={headCells}
                  classes={classes}
                  numSelected={0}
                  order={orderData.sortDir}
                  orderBy={orderData.sortCol}
                  onRequestSort={handleRequestSort}
                  rowCount={data.data.length}
                />
                <TableBody>
                  {data.data.map((row) => {
                    const { id } = syncOfficeStore.params || {};
                    const { syncing } = syncOfficeStore.loading;

                    return (
                      <>
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row}
                        >
                          <TableCell align="left">{row.tenant_id || "-"}</TableCell>
                          <TableCell align="left">{row.firstname}</TableCell>
                          <TableCell align="left">{row.lastname}</TableCell>
                          <TableCell align="right">{row.telnyx_number || "-"}</TableCell>
                          <TableCell align="right">{row.timezone || "-"}</TableCell>
                          <TableCell align="right">{row.status === "0" ? "Enabled" : "Disabled"}</TableCell>
                          <TableCell align="right" scope="row">
                            <Link to={{
                              pathname: `/office/${row.id}/edit`
                            }}>
                              <Tooltip title="Edit" aria-label="edit">
                                <IconButton color="secondary" aria-label="Edit" component="span" >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </Link>
                            <Tooltip title="Sync with OpenDental" aria-label="sync">
                              <IconButton color="primary" aria-label="Sync with OpenDental"
                                component="span"
                                onClick={() => {
                                  syncOffice({ id: row.id });
                                }}>
                                <RefreshIcon />
                                {row.id === id && syncing && "Loading..."}
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        {/* ) : null} */}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.meta.ROW_COUNT || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </div>
  ));
});