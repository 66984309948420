import React, { useEffect, useState } from "react";
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import './jsonLoadSettings.css';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Modal from '../../components/Core/Modal';

const LoadJson = (props) => {
    const [importedSchemaEdit, setImportedSchemaEdit] = useState();
    const [showJsonLoader, setShowJsonLoader] = useState();

    useEffect(() => {
        if (props.showJsonLoader) {
            setShowJsonLoader(props.showJsonLoader);
        }
    }, [props.showJsonLoader]);

    const handleLoadJsonClose = (event) => {
        setShowJsonLoader(false);
        props.parentCallback(importedSchemaEdit);
    }

    return (
        <>
            {showJsonLoader &&
                <Popper
                    open={props.showJsonLoader}
                    anchorEl={undefined}
                    role={undefined}
                    transition
                    disablePortal
                    placement="bottom-start"
                    style={{ zIndex: 99 }}
                >
                    <Modal elevation={8}
                        header="Load JSON"
                        size="md"
                        body={
                            <div id="JsonLoader">
                                <div className="container FormBuilder">
                                    <textarea cols={100}
                                        onChange={(e) => {
                                            try {
                                                setImportedSchemaEdit(JSON.parse(e.target.value));
                                            } catch (e) {
                                                setImportedSchemaEdit({});
                                            }
                                        }}
                                    ></textarea>
                                    <br/>
                                    <Button
                                        variant="outlined"
                                        startIcon={<FileCopyIcon />}
                                        onClick={handleLoadJsonClose}>
                                        Set
                                    </Button>
                                </div>
                            </div>
                        }
                        onClose={handleLoadJsonClose}
                    />
                </Popper >
            }
        </>
    );
};
export default LoadJson;